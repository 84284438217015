<template>
    <div class="body">
        <div class="content">
            <div class="text">
                <p style="color: #2B6DC5;font-weight: bold;font-size: 23px;line-height: 30px;margin-bottom: 20px;">{{ data.items[0].title }}</p>
                <p style="font-size: 13px;line-height:33px;color: #010101;">{{ data.items[0].description }}</p><br>
                <p style="font-size:18px; font-weight: bold;line-height: 29px;margin-bottom: 20px;">{{ data.items[1].title }}</p>
                <div class="description">{{ data.items[1].description }}</div>
            </div>
            <div class="image2"><img :src="data.items[1].cover"></div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  created() {
    console.log('应用案例', this.data);
  }
};
</script>

<style lang="scss" scoped>
.body {
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
    padding-top: 50px;
    padding-bottom: 50px;
}

@media screen and (min-width: 700px) {
    .content {
        display: flex;
        width: 100%;
        margin: 0 0 0 auto;
        justify-content: space-between;
    }
}

.text {
    width:45%;
    // margin-top: 30px;
    margin-right: 35px;
}

.description {
    padding-bottom: 20px;
    font-size: 13px;
    line-height:35px;
    white-space: pre-line;
}
.image2 {
    width: 46%;
    margin-top: 30px;
    // margin-left:60px
}

.image2 img {
    width: 100%;
    display: block;
}

@media screen and (max-width: 700px) {
    .body {
        margin: 0 auto;
        padding-top: 50px;
    }

    .content {
        width: 100%;
    }

    .image2 {
        margin: 0 auto;
        width: 94%;
        // margin-top: 10px;
        margin-bottom: 30px;
    }

    .image2 img {
        width: 100%;
        display: block;
    }

    .text {
        width: 94%;
        margin: 0 auto;
    }

    .description {
        font-size: 18px;
        line-height: 30px;
        color: #666;
    }
}
</style>
